.background {
  height: 400px;
  width: auto;
  background-size: cover;
  background-image: url("../images/meadowlark2.jpg");
  background-repeat: no-repeat;
  /* Can I get the picture to shift down more? */
  background-position-x: 20%;
}

.translucent-box {
  position: absolute;
  text-align: center;
  left: 10%;
  right: 10%;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  min-width: 250px;
  /* background-color: white; */
  opacity: 100%;
  top: 80px;
}

.translucent-box p {
  color: black;
  font-size: 30px;
}

.landing-info-container-grey {
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing-info-container-purple {
  background-color: #2a006e;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing-info-image-purple,
.landing-info-image-grey {
  border-radius: 5px;
  width: 80%;
  object-fit: contain;
  box-shadow: 2px 2px 2px 2px rgb(58, 58, 58);
}

.landing-info-description-grey {
  color: black;
  text-align: left;
  width: 80%;
}

.landing-info-description-purple {
  color: white;
  text-align: left;
  width: 80%;
}

.landing-info-description-purple p {
  font-size: 20px;
  margin: 20px 0;
  max-width: 350px;
}

.landing-info-description-grey p {
  font-size: 20px;
  margin: 20px 0;
  max-width: 350px;
}

.demo-video-label {
  text-align: center;
  color: white;
}

.video-container {
  width: 80%;
  object-fit: contain;
  border-radius: 5px;
  /* box-shadow: 2px 2px 2px 2px rgb(58, 58, 58); */
}

.meadowlark-icon {
  filter: invert(100%);
  height: 40px;
  margin-right: 10px;
}

@media (min-width: 650px) {
  .background {
    height: 400px;
    width: auto;
    background-size: cover;
    background-image: url("../images/meadowlarkOG.jpg");
    background-position: 30%;
  }
  /*
  .translucent-box {
    position: absolute;
    text-align: center;
    left: 10%;
    right: 10%;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
    min-width: 300px;
    /* background-color: white; */
  /* opacity: 100%;
    top: 80px;
  } */

  .translucent-box p {
    color: black;
    font-size: 35px;
  }

  .translucent-box {
    position: absolute;
    left: 50%;
    right: 10%;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    min-width: 300px;
    top: 150px;
    opacity: 100%;
  }

  .landing-info-container-purple,
  .landing-info-container-grey {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .landing-info-description-purple {
    order: 1;
    margin: 50px;
    width: 40%;
  }

  .landing-info-description-grey {
    order: 0;
    margin: 50px;
    width: 40%;
  }

  .landing-info-image-purple {
    order: 0;
    width: 50%;
    border-radius: 5px;
    margin: 50px;
    overflow: hidden;
  }

  .landing-info-image-grey {
    order: 1;
    width: 50%;
    border-radius: 5px;
    margin: 50px;
    overflow: hidden;
  }

  .video-container {
    order: 1;
    width: 50%;
    border-radius: 5px;
    margin: 50px;
    overflow: hidden;
  }
}
