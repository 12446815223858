.no-wrap {
  white-space: nowrap;
}

.main-navigation__menu-btn {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 1rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 2rem;
  height: 2.5px;
  background: white;
}

.main-navigation__title {
  color: white;
  font-weight: normal;
  font-size: 25px;
}

.meadowlark-icon {
  display: none;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}

.main-navigation-logo-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.banner {
  display: none;
}

.tooltip-container {
  display: flex;
  justify-self: flex-end;
  justify-content: center;
  min-width: 100px;
}

.user-icon {
  align-self: center;
}

.user-tooltip {
  position: relative;
}

/* Tooltip text */
.user-tooltip .tooltip-text {
  visibility: hidden;
  width: 150px;
  background-color: paleturquoise;
  color: black;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  top: 35px;

  /* Position the tooltip text */
  position: relative;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -150px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.5s;
}

/* Show the tooltip text when you mouse over the tooltip container */
.user-tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.right-space {
  width: 0;
}

.left-space {
  width: 100%;
}

@media (min-width: 500px) {
  .main-navigation__title {
    color: white;
    font-size: 35px;
  }
}

@media (min-width: 750px) {
  .banner {
    display: block;
    height: 350px;
    border: 1px solid black;
    background-image: url("../../../images/heron-banner5.jpg");
    background-repeat: no-repeat;
    background-position: 60%;
    background-size: cover;
  }

  .meadowlark-icon {
    display: flex;
  }

  .main-navigation__menu-btn {
    width: 3rem;
    height: 3rem;
    background: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 2rem;
    cursor: pointer;
  }

  .main-navigation__menu-btn span {
    display: block;
    width: 3rem;
    height: 2.5px;
    background: white;
  }

  .main-navigation__title {
    color: white;
    text-decoration: none;
    font-size: 35px;
  }
}

@media (min-width: 1200px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }

  .right-space {
    width: 0;
  }

  .left-space {
    width: 100%;
  }
}
