.split-panels-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.filter-panel {
  padding: 0;
  width: auto;
  max-height: 1000px;
  background-color: lightgray;
  /* border: 1px solid black; */
  border-radius: 10px;
  margin: 0 10px;
  transition: all 400ms ease-in-out;
}

.closed {
  max-height: 30px;
}

.filter-open-button-row {
  display: flex;
  justify-content: center;
  padding: px 0;
  border: 1px solid black;
  border-top: 1px solid black;
  background: lightgray;
}

@media (min-width: 769px) {
  .split-panels-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }

  .filter-panel {
    padding: 10px;
    width: 250px;
    min-width: 250px;
    margin-left: 10px;
    height: 656px;
    margin: 0 0 0 10px;
  }

  .content-panel {
    flex-grow: 1;
  }
}



