.button {
  font: inherit;
  white-space: nowrap;
  padding: 0.5rem 1.5rem;
  border: 1px solid #9d00ff;
  border-radius: 4px;
  height: 38px;
  background: #9d00ff;
  color: white;
  cursor: pointer;
  margin: 0 0.5rem;
  text-decoration: none;
  display: inline-block;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  color: white;
  background: #d000ff;
  border-color: #d000ff;
}

.button--inverse {
  background: transparent;
  color: #9900ff;
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: #9900ff;
}

.button--danger {
  background: #830000;
  border-color: #830000;
}

.button--danger:hover,
.button--danger:active {
  background: #f34343;
  border-color: #f34343;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}
