html, body {
  height: 100vh;
  width: 100vw;
  min-width: 300px;
}

main {
  height: auto;
  width: 100%;
  margin: 10px 0;
}

div#root {
  height: 100%;
  width: 100%;
}

.input-height {
  height: 38px;
}

.react-datepicker__input-container {
  height: 38px;
}

.react-datepicker__input-container input {
  min-height: 38px;
}

/* Make me a flex box so we can use space-around? */
/* .stats-dates-container input {
  height: 38px;
} */


a:hover {
  color: paleturquoise;
}
