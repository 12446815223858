.footer-container {
  color: #2A006E;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: gray;
  border-radius: 10px;
  margin: 10px 10px 20px;
  padding: 20px;
}

.footer-break {
  height: 2rem;
  background-color: #29006E;
}

.footer-field h3 {
  text-align: center;
}

.footer-icons {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 250px;
}

.footer-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-field {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  margin: 0 10px;
}

.footer-icon:hover {
  color: paleturquoise;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#binIcon {
  display: none;
}

.footer-container a {
  color: inherit;
  text-decoration: none;
  padding: none;
  text-align: center;
  line-height: 0;
}

@media (min-width: 400px) {
  .footer-field {
    width: 335;
  }
}


@media (min-width: 850px) {
  .footer-container {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
}

@media (min-width: 980px) {
  #binIcon {
    display: inline-block;
    color: rgb(166, 164, 164)
  }
}