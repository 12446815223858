@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat");

* {
  box-sizing: border-box;
}

html {
  /* font-family: 'Open Sans', sans-serif; */
  font-family: "Montserrat", "Open Sans", sans-serif;
}

body {
  margin: 0;
  background: gray;
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.slide-in-top-enter {
  opacity: 0;
  transform: translateY(-100%);
}

.slide-in-top-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-top-exit {
  transform: translateY(0%);
  opacity: 1;
}

.slide-in-top-exit-active {
  transform: translateY(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-panel-enter {
  opacity: 0;
  transform-origin: top;
  transform: scaleY(0);
}

.filter-panel-enter-active {
  opacity: 1;
  transform-origin: top;
  transform: scaleY(1);
  transition: all 500ms ease-in-out;
}

.filter-panel-exit {
  /* transform-origin: top;
  transform: scaleY(1); */
  opacity: 1;
}

.filter-panel-exit-active {
  opacity: 0;
  /* transform-origin: top;
  transform: scaleY(0.4); */
  transition: all 400ms;
}
